<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-07 12:58:42
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-03-20 10:23:04
 -->
<template>
  <div>
    <md-field>
      <div class="family"
           v-for="(item,index) in form.family"
           :key="index">
        <md-input-item :title="'家庭成员信息'+(index+1)"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-field-item title="家庭关系："
                       :content="item.famshipName"
                       @click="showofamilyship(index)"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false">
        </md-field-item>

        <md-selector v-model="familySelectorShow[index]"
                     :data="familyselectdata"
                     title="家庭关系"
                     default-value="1"
                     large-radius
                     @show="showwhich(index)"
                     @choose="familytypeChoose"></md-selector>
        <md-input-item title="姓名："
                       placeholder="姓名"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.name"
                       align="right"></md-input-item>
        <md-field-item title="证件类型："
                       :content="item.cardName"
                       @click="showcardSelector(index)"
                       :disabled="checkstatus === 't'?false:true"
                       arrow
                       :solid="false">
        </md-field-item>

        <md-selector v-model="cardSelectorShow[index]"
                     :data="cardselectdata"
                     title="证件类型"
                     default-value="1"
                     @show="showwhich(index)"
                     large-radius
                     @choose="oncardChoose"></md-selector>
        <md-input-item title="证件号码："
                       placeholder="证件号码"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.cardnum"
                       align="right"></md-input-item>
        <md-input-item title="联系方式："
                       placeholder="联系方式"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.famtel"
                       align="right"></md-input-item>
        <md-input-item title="工作单位："
                       placeholder="工作单位"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.famwork"
                       align="right"></md-input-item>
        <md-input-item title="职务："
                       placeholder="职务"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.famzhiwu"
                       align="right"></md-input-item>
        <md-button :type="(form.family.length == 1)?'disabled':'warning'"
                   v-if="checkstatus !== 't'?false:true"
                   plain
                   round
                   @click="del(index)">删除</md-button>
      </div>
      <md-button class="addbtn"
                 :type="checkstatus !== 't' ?'disabled':'primary'"
                 plain
                 round
                 @click="add">添加家庭成员</md-button>
      <div class="btn">
        <md-button type="primary"
                   @click="back"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{checkstatus == 't'?'上一步':'上一页'}}</md-button>
        <md-button type="primary"
                   @click="saveform"
                   v-if="checkstatus === 't' || (checkstatus != 't' && btntext == '下一页')"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{btntext}}</md-button>
      </div>
    </md-field>
  </div>
</template>
<script>
import { addmember } from '@/api/agent/agent'
import { Button, DatePicker, InputItem, Field, Toast, Selector, FieldItem } from 'mand-mobile'
export default {
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button
  },
  data () {
    return {
      btntext: '下一步', // 按钮内容
      // 下拉
      item: '', // 控制下拉框
      familyship: [],
      cardtype: [],
      familySelectorShow: [false],
      cardSelectorShow: [false],
      cardselectdata: [
        {
          value: '1',
          text: '身份证'
        },
        {
          value: '2',
          text: '军官证'
        }
      ],
      familyselectdata: [
        {
          value: '1',
          text: '父子'
        },
        {
          value: '2',
          text: '父女'
        }
      ],
      form: {},
      incomset: {},
      // 是否可以修改
      checkstatus: '',
      isdzrs: false
    }
  },
  created () {
    this.familyselectdata = this.$parent.familyshipdata
    this.cardselectdata = this.$parent.cardselectdata
    this.checkstatus = this.$parent.checkstatus
    this.form = this.$parent.form
    this.incomset = this.$parent.incomset
    this.isdzrs = this.$parent.isdzrs
    if (this.incomset.educations || this.incomset.works) {
      this.btntext = this.checkstatus == 't' ? '下一步' : '下一页'
    } else { this.btntext = '提交申请' }
  },
  updated () {
    this.checkstatus = this.$parent.checkstatus
  },
  methods: {
    saveform () {
      if (this.incomset.educations) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'edu'
      } else if (this.incomset.works) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'work'
      } else {
        addmember(this.form).then(res => {
          if (this.isdzrs) {
            // location.reload()
            Toast.succeed('资料填写完毕，请继续学习保险基础知识')
            this.$router.push('/fundamentals')
          } else {
            Toast.succeed('入司申请成功')
            this.$router.push('/successpage')
          }
        })
      }
    },
    showofamilyship (item) {
      this.familySelectorShow[item] = true
      this.$forceUpdate()
    },
    showcardSelector (item) {
      this.cardSelectorShow[item] = true
      this.$forceUpdate()
    },
    showwhich (item) {
      this.item = item
    },
    familytypeChoose ({ text, value }) {
      this.form.family[this.item].famshipName = text
      this.form.family[this.item].famship = value
    },
    oncardChoose ({ text, value }) {
      this.form.family[this.item].cardName = text
      this.form.family[this.item].cardtype = value
    },
    // 添加删除
    del (index) {
      this.form.family.splice(index, 1)
    },
    add () {
      this.form.family.push({ famship: '', name: '', cardtype: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' })
    },
    back () {
      this.$parent.type = this.$parent.currenttype[this.$parent.currenttype.length - 1]
      this.$parent.currenttype.pop()
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

.addbtn {
  margin: 20px 0;
}

.btn {
  display: flex;

  .md-button {
    flex: 1;
    margin: 5px;
  }
}
</style>
