<template>
  <div>
    <md-field>
      <div class="work"
           v-for="(item,index) in form.work"
           :key="index">
        <md-input-item :title="'工作经历'+(index+1)"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="工作单位："
                       placeholder="工作单位"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.workcompany"
                       align="right"></md-input-item>
        <md-field-item title="起始年限："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       :content="item.begdate"
                       @click.native="startDateShow(index)">
        </md-field-item>
        <md-date-picker ref="start"
                        v-model="istartDateShow[index]"
                        v-if="checkstatus === 't'"
                        type="custom"
                        title="选择起始年限"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onstartDateChange(index)"
                        @confirm="onstartConfirm(index)"></md-date-picker>
        <md-field-item title="终止年限："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       :content="item.enddate"
                       @click.native="endDateShow(index)">
        </md-field-item>
        <md-date-picker ref="end"
                        v-model="isendDateShow[index]"
                        v-if="checkstatus === 't'"
                        type="custom"
                        title="选择终止年限"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onendDateChange(index)"
                        @confirm="onendConfirm(index)"></md-date-picker>
        <md-input-item title="职务："
                       placeholder="职务"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.zhiwu"
                       align="right"></md-input-item>
        <md-input-item title="证明人："
                       placeholder="证明人"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.zmren"
                       align="right"></md-input-item>
        <md-textarea-item title="离职原因："
                          autosize
                          :readonly="checkstatus === 't'?false:true"
                          :solid="false"
                          class="example"
                          v-model="item.reason"
                          placeholder="请输入离职原因" />
        <md-button :type="(form.work.length === 1)?'disabled':'warning'"
                   v-if="checkstatus !== 't'?false:true"
                   plain
                   round
                   @click="del(index)">删除</md-button>
      </div>
      <md-button class="addbtn"
                 :type="checkstatus !== 't' ?'disabled':'primary'"
                 plain
                 round
                 @click="add">添加工作经历</md-button>
      <div class="btn">
        <md-button type="primary"
                   @click="back"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{checkstatus == 't'?'上一步':'上一页'}}</md-button>
        <md-button type="primary"
                   @click="saveform"
                   v-if="checkstatus !== 't'?false:true"
                   :loading="loading"
                   :inactive="inactive"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{btntext}}</md-button>
      </div>
    </md-field>
  </div>
</template>
<script>
import { addmember } from '@/api/agent/agent'
import { Button, DatePicker, InputItem, Field, TextareaItem, Toast, Selector, FieldItem } from 'mand-mobile'
export default {
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [TextareaItem.name]: TextareaItem,
    [Button.name]: Button
  },
  data () {
    return {
      loading: false,
      inactive: false,
      btntext: '提交申请', // 按钮内容
      // 下拉
      item: '',
      xueli: [],
      begdate: [],
      enddate: [],
      eduselectdata: [],
      istartDateShow: [false],
      isendDateShow: [false],
      eduSelectorShow: [false],
      form: {},
      checkstatus: '',
      isdzrs: false
    }
  },
  created () {
    this.checkstatus = this.$parent.checkstatus
    this.isdzrs = this.$parent.isdzrs
    this.form = this.$parent.form
  },
  updated () {
    this.checkstatus = this.$parent.checkstatus
  },
  methods: {
    saveform () {
      this.loading = true
      this.inactive = true
      addmember(this.form).then(res => {
        this.loading = false
        this.inactive = false
        if (this.isdzrs) {
          // location.reload()
          Toast.succeed('资料填写完毕，请继续学习保险基础知识')
          this.$router.push('/fundamentals')
        } else {
          Toast.succeed('入司申请成功')
          this.$router.push('/successpage')
        }
      })
    },
    showeduSelector (index) {
      this.eduSelectorShow[index] = true
      this.$forceUpdate()
    },
    showwhich (index) { this.item = index },
    oneduChoose ({ value, text }) {
      this.xueli[this.item] = text
      this.form.work[this.item].xueli = value
    },
    startDateShow (index) {
      this.istartDateShow[index] = true
      this.$forceUpdate()
    },
    onstartDateChange (index) {

    },
    onstartConfirm (index) {
      this.form.work[index].begdate = this.$refs['start'][index].getFormatDate('yyyy-MM-dd')
      this.begdate[index] = this.form.work[index].begdate
      this.$forceUpdate()
    },
    endDateShow (index) {
      this.isendDateShow[index] = true
      this.$forceUpdate()
    },
    onendDateChange (index) { },
    onendConfirm (index) {
      this.form.work[index].enddate = this.$refs['end'][index].getFormatDate('yyyy-MM-dd')
      this.enddate[index] = this.form.work[index].enddate
      this.$forceUpdate()
    },
    // 添加删除
    del (index) {
      this.form.work.splice(index, 1)
    },
    add () {
      this.form.work.push({ famship: '', name: '', cardtype: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' })
    },
    back () {
      this.$parent.type = this.$parent.currenttype[this.$parent.currenttype.length - 1]
      this.$parent.currenttype.pop()
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

.addbtn {
  margin: 20px 0;
}

.btn {
  display: flex;

  .md-button {
    flex: 1;
    margin: 5px;
  }
}
</style>
