<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-05 11:08:02
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2020-07-22 18:11:44
 -->
<template>
  <div class="topbox">
    <div class="baseinfo"
         v-if="type == 'baseinfo'">
      <md-field v-if="!this.isnhwx">
        <md-input-item title="推荐人信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="推荐人姓名："
                       :solid="false"
                       readonly
                       v-model="user.tjname"
                       align="right"></md-input-item>
        <md-input-item title="推荐人工号："
                       v-model="user.tjempno"
                       :solid="false"
                       readonly
                       align="right"
                       class="marginb"></md-input-item>
      </md-field>
      <md-field v-if="!this.isnhwx">
        <md-input-item title="上级主管信息"
                       class="tjmes"
                       disabled
                       :solid="false"
                       v-if="!this.isnhwx"></md-input-item>
        <md-input-item title="上级主管姓名："
                       :solid="false"
                       readonly
                       v-model="user.upname"
                       align="right"
                       v-if="!this.isnhwx"></md-input-item>
        <md-input-item title="上级主管工号："
                       v-model="user.upempno"
                       :solid="false"
                       readonly
                       align="right"
                       v-if="!this.isnhwx"></md-input-item>
      </md-field>
      <md-field v-if="!this.isnhwx">
        <md-input-item title="育成人信息"
                       class="tjmes"
                       disabled
                       :solid="false"
                       v-if="!this.isnhwx"></md-input-item>
        <md-input-item title="育成人姓名："
                       :solid="false"
                       readonly
                       v-model="user.ycname"
                       align="right"
                       v-if="!this.isnhwx"></md-input-item>
        <md-input-item title="育成人工号："
                       v-model="user.ycempno"
                       :solid="false"
                       readonly
                       align="right"
                       v-if="!this.isnhwx"></md-input-item>
      </md-field>
      <md-field>
        <!-- 、银行卡影像 -->
        <md-input-item title="上传证件号(正、反面)"
                       :solid="false"
                       disabled
                       class="tjmes require"></md-input-item>
        <ul class="image-reader-list">
          <li class="image-reader-item add"
              v-if="cardfront === ''">
            <md-image-reader name="reader0"
                             @select="oncardfrontSelect"
                             @complete="oncardfrontComplete"
                             @error="oncardfrontError"
                             is-multiple
                             v-if="checkstatus === 't'"></md-image-reader>
            <img src="@/assets/nh/zjz.png"
                 alt="">
            <p>证件正面</p>
          </li>
          <li class="image-reader-item"
              v-if="cardfront !== ''">
            <div :style="{
          'backgroundImage': `url(${cardfront}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

            </div>
            <p>证件正面</p>
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('cardfront')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item add"
              v-if="cardback === ''">
            <md-image-reader name="reader0"
                             @select="oncardbackSelect"
                             @complete="oncardbackComplete"
                             @error="oncardbackError"
                             is-multiple
                             v-if="checkstatus === 't'"></md-image-reader>
            <img src="@/assets/nh/zjf.png"
                 alt="">
            <p>证件反面</p>
          </li>
          <li class="image-reader-item"
              v-if="cardback !== ''">
            <div :style="{
          'backgroundImage': `url(${cardback}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

            </div>
            <p>证件反面</p>
            <md-tag class="image-reader-item-del"
                    size="small"
                    shape="quarter"
                    v-if="checkstatus === 't'"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('cardback')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
        </ul>
        <!-- <ul class="image-reader-list">
          <li class="image-reader-item add"
              v-if="bankcard === ''">
            <md-image-reader name="reader0"
                             @select="onbankcardSelect"
                             @complete="onbankcardComplete"
                             @error="onbankcardError"
                             is-multiple
                             v-if="checkstatus === 't'"></md-image-reader>
            <img src="@/assets/nh/yhk.png"
                 alt="">
            <p>银行卡</p>
          </li>
          <li class="image-reader-item"
              v-if="bankcard !== ''">
            <div :style="{
          'backgroundImage': `url(${bankcard}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

            </div>
            <p>银行卡</p>
            <md-tag class="image-reader-item-del"
                    size="small"
                    shape="quarter"
                    v-if="checkstatus === 't'"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('bankcard')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
        </ul> -->
      </md-field>
      <md-field>
        <md-input-item title="上传学历证书和一寸免冠照影像"
                       :solid="false"
                       disabled
                       class="tjmes require"></md-input-item>
                       <!-- class="tjmes"></md-input-item> -->
        <ul class="image-reader-list">
          <li class="image-reader-item add"
              v-if="edu === ''">
            <md-image-reader name="reader0"
                             @select="oneduSelect"
                             @complete="oneduComplete"
                             @error="oneduError"
                             is-multiple
                             v-if="checkstatus === 't'"></md-image-reader>
            <!-- <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon> -->
            <img src="@/assets/nh/xlzs.png"
                 alt="">
            <p>学历证书</p>
          </li>
          <li class="image-reader-item"
              v-if="edu !== ''">
            <div :style="{
          'backgroundImage': `url(${edu}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

            </div>
            <p>学历证书</p>
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('edu')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item add"
              v-if="headpic === ''">
            <md-image-reader name="reader0"
                             @select="onheadpicSelect"
                             @complete="onheadpicComplete"
                             @error="onheadpicError"
                             is-multiple
                             v-if="checkstatus === 't'"></md-image-reader>
            <!-- <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon> -->
            <img src="@/assets/nh/mgz.png"
                 alt="">
            <p>一寸免冠照</p>
          </li>
          <li class="image-reader-item"
              v-if="headpic !== ''">
            <div :style="{
          'backgroundImage': `url(${headpic}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            </div>
            <p>一寸免冠照</p>
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('headpic')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
        </ul>
      </md-field>
      <md-field>
        <md-input-item title="申请人信息"
                       :solid="false"
                       disabled
                       class="tjmes"></md-input-item>
        <md-field-item title="所属职级："
                       :content="degreename"
                       @click="showzjSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false">
        </md-field-item>

        <md-selector v-model="zjSelectorShow"
                     :data="zjlist"
                     title="所属职级："
                     large-radius
                     @choose="onzjChoose"></md-selector>
        <md-input-item title="姓名："
                       placeholder="姓名"
                       class="require"
                       :solid="false"
                       name="empname"
                       :readonly="checkstatus === 't'?false:true"
                       v-validate="'required'"
                       :error="errors.first('empname')"
                       v-model="form.salesmanInfo.empname"
                       align="right"></md-input-item>
        <md-field-item title="证件类型："
                       :content="cardtype"
                       @click="showcardSelector"
                       class="require"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
        <md-selector v-model="cardSelectorShow"
                     :data="cardselectdata"
                     title="证件类型"
                     default-value="1"
                     large-radius
                     @choose="oncardtypeChoose"></md-selector>
        <md-input-item title="证件号码："
                       placeholder="证件号码"
                       :solid="false"
                       name="cardnum"
                       :readonly="checkstatus === 't'?false:true"
                       class="require"
                       @blur="inputcardnum"
                       v-validate="'required'"
                       :error="errors.first('cardnum')"
                       v-model="form.salesmanInfo.cardnum"
                       align="right"></md-input-item>
        <md-field-item title="性别："
                       :content="sex"
                       class="require"
                       @click="showsexSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="sexSelectorShow"
                     :data="sexselectdata"
                     title="性别"
                     large-radius
                     @choose="onsexChoose"></md-selector>
        <md-field-item title="民族："
                       :content="nation"
                       @click="shownationSelector"
                       class="require"
                       :disabled="checkstatus === 't'?false:true"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="nationSelectorShow"
                     max-height="400px"
                     :data="nationselectdata"
                     title="民族"
                     large-radius
                     @choose="onnationChoose"></md-selector>

        <md-field-item title="出生日期："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       class="require"
                       :content="form.salesmanInfo.birth"
                       @click.native="isDatePickerShow = true"
                       placeholder="请选择">
        </md-field-item>
        <md-date-picker ref="datePicker2"
                        v-model="isDatePickerShow"
                        type="custom"
                        v-if="checkstatus === 't'"
                        title="选择出生日期"
                        :min-date="minDate"
                        :default-date="defaultDate"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onDatePickerChange"
                        @confirm="onDatePickerConfirm"></md-date-picker>
        <md-input-item title="详细地址："
                       placeholder="详细地址"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.salesmanInfo.zaddr"
                       align="right"></md-input-item>

        <md-field-item title="政治面貌："
                       class="require"
                       :content="political"
                       :disabled="checkstatus === 't'?false:true"
                       @click="showepolitical"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
        <md-selector v-model="politicalShow"
                     :data="politicaldata"
                     title="政治面貌"
                     min-height="40vh"
                     max-height="40vh"
                     large-radius
                     @choose="politicalChoose"></md-selector>
        <md-field-item title="学历："
                       class="require"
                       :disabled="checkstatus === 't'?false:true"
                       :content="educational"
                       @click="showeduSelector"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="eduSelectorShow"
                     :data="eduselectdata"
                     title="学历"
                     large-radius
                     @choose="oneduChoose"></md-selector>
        <md-input-item title="邮箱："
                       placeholder="邮箱"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.salesmanInfo.email"
                       align="right"
                       v-validate="'email'"
                       name="email"
                       :error="errors.first('email')"></md-input-item>
      </md-field>
      <!-- <md-field>
        <md-input-item title="银行卡信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-field-item title="银行账户："
                       :content="bankcode"
                       @click="showbankSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="bankSelectorShow"
                     :data="bankselectdata"
                     title="银行账户"
                     large-radius
                     max-height="350px"
                     @choose="onbankChoose"></md-selector>
        <md-input-item title="银行卡号："
                       placeholder="银行卡号"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.salesmanInfo.bankno"
                       align="right"
                       v-validate="'credit'"
                       name="credit"
                       :error="errors.first('credit')"></md-input-item>
        <md-input-item title="开户支行："
                       placeholder="开户支行"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.salesmanInfo.bankzh"
                       align="right"></md-input-item>
      </md-field> -->
      <md-field class="handsign">
        <md-input-item title="手写签名"
                       class="tjmes require"
                       disabled
                       :solid="false"></md-input-item>
        <div style="background: #fff;padding: 10px;height: 6rem;position: relative">
          <div v-if="autograph === '' && selfautograph === '' && checkstatus === 't'"
               class="
               item_title"
               style="width: 100%;height:100%;border: 1px solid #f0f0f0;"
               id="contain"
               @touchstart="subtouchStart($event)">
            <VueSignaturePad width="100%"
                             height="100%"
                             ref="signaturePad" />
            <div style="position: absolute;right: 12px;top:-33px;z-index:999;color:#EC2D38;"
                 @touchend="clearcanvas('qm')">删除</div>
          </div>
          <div class="signature item_title"
               style="width: 100%;height:100%;border: 1px solid #f0f0f0;"
               v-else>
            <img :src="autograph"
                 alt="签名"
                 style="width:100%;height:100%"
                 v-if="autograph">
            <div style="position: absolute;right: 12px;top:-33px;color:#EC2D38;z-index:999;"
                 @touchend="clearcanvas('tp')"
                 v-if="!readonly">删除</div>
          </div>
        </div>
      </md-field>
      <md-button class="submit"
                 type="primary"
                 :loading="btnloading"
                 :inactive="inactive"
                 v-if="checkstatus === 't'"
                 @click="saveform">{{btntext}}</md-button>
    </div>
    <div class="xyword"
         v-if="checkstatus === 't'">
      <p class="frontpic">
        <img src="@/assets/nh/uncheck.png"
             alt=""
             v-if="!isxz"
             @click="isxz = !isxz">
        <img src="@/assets/nh/check.png"
             alt=""
             v-else
             @click="isxz = !isxz">
        我已阅读并接受<span @click="toshow()">《保险经纪业务委托协议》</span>
      </p>
    </div>
    <md-landscape v-model="showFullScreen"
                  full-screen
                  class="landscape">
      <div class="pdf">
        <div>
          <pdf v-for="i in numPages"
               :key="i"
               ref="pdf"
               :page="i"
               :src="filepath"
               style="width: 100%; height: auto;"
               @num-pages="pageCount=$event"></pdf>
        </div>
      </div>
    </md-landscape>
    <div class="closepdf"
         v-if="showFullScreen"
         @click="showFullScreen = !showFullScreen">关闭</div>
         <div>
            <md-toast  md-toast ref="toast">
              <md-icon :name="dong.value" size="lg"></md-icon>{{dong.label}}
            </md-toast>
         </div>
  </div>
</template>
<script>
import { getSelectList, showEmpInfo, addmember, ididentify, uploadfiles, bankidentify, getRelationInfo, getMinZJ, selectBaseBankcode } from '@/api/agent/agent'
import { Dialog, Button, DatePicker, InputItem, Field, Icon, ImageReader, Tag, Toast, Selector, FieldItem, TabPicker } from 'mand-mobile'
import gua from './guarantor'
import edu from './edu'
import family from './family'
import work from './work'
import { cloneDeep } from 'lodash'
import { getStorage } from '@/lib/util'
import pdf from 'vue-pdf'
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      showEmpInfo().then(res => {
        vm.checkRytype = res.data.data.salesmanInfo.checkRytype
        if (vm.checkRytype == '4') {
          vm.checkstatus = 't'
          if (vm.form.salesmanInfo.tjempno && !vm.isnhwx) {
            vm.getRelation()
          }
          if (vm.userinfo.mobile) {
            vm.form.salesmanInfo.phone = vm.userinfo.mobile
          }
          if (vm.$route.query.cardno) {
            vm.form.salesmanInfo.cardnum = vm.$route.query.cardno
            vm.oncardtypeChoose({ value: '111', text: '居民身份证' })
          }
          if (vm.$route.query.empname) {
            vm.form.salesmanInfo.empname = vm.$route.query.empname
          }
        } else {
          vm.readonly = true
          vm.form = res.data.data
          if (!res.data.data.guarantorInfo ||
            res.data.data.guarantorInfo.length == '0') {
            vm.form.guarantorInfo = [{ guatype: '0', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }, { guatype: '1', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }]
          } else if (res.data.data.guarantorInfo.length == 1) {
            if (res.data.data.guarantorInfo[0].guatype == '0') {
              res.data.data.guarantorInfo.push({
                guatype: '1',
                guaempno: '',
                guaname: '',
                guacardtype: '',
                guacardnum: '',
                guazhiwu: '',
                guatel: '',
                guaaddr: ''
              })
              vm.form.guarantorInfo = res.data.data.guarantorInfo
            } else {
              res.data.data.guarantorInfo.unhsift({
                guatype: '0',
                guaempno: '',
                guaname: '',
                guacardtype: '',
                guacardnum: '',
                guazhiwu: '',
                guatel: '',
                guaaddr: ''
              })
              vm.form.guarantorInfo = res.data.data.guarantorInfo
            }
          } else {
            vm.form.guarantorInfo = res.data.data.guarantorInfo
          }
          if (!vm.form.family) {
            vm.form.family = [{ famship: '', famshipName: '', name: '', cardtype: '', cardName: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' }]
          }
          if (!vm.form.edu) {
            vm.form.edu = [{ school: '', begdate: '', enddate: '', xueli: '', xueliname: '', zmr: '' }]
          }
          if (!vm.form.work) {
            vm.form.work = [{ workcompany: '', begdate: '', enddate: '', zhiwu: '', zmren: '', reason: '' }]
          }
          if (!vm.form.sourceinfo) {
            vm.form.sourceinfo = []
          }
          vm.form.guarantorInfo.forEach((item, index) => {
            vm.incardtype = vm.form.guarantorInfo[0].guacardname
            vm.outcardtype = vm.form.guarantorInfo[1].guacardname
          })
          vm.initpic()
          vm.degreename = vm.form.salesmanInfo.degreename
          vm.cardtype = vm.form.salesmanInfo.cardName
          vm.sex = vm.form.salesmanInfo.sexName
          vm.nation = vm.form.salesmanInfo.mzName
          vm.educational = vm.form.salesmanInfo.eduName
          vm.political = vm.form.salesmanInfo.zzmmName
          vm.checkstatus = vm.form.salesmanInfo.status
          vm.user.tjname = vm.form.salesmanInfo.tjname
          vm.user.tjempno = vm.form.salesmanInfo.tjempno
          vm.user.upname = vm.form.salesmanInfo.upname
          vm.user.upempno = vm.form.salesmanInfo.upempno
          vm.user.ycname = vm.form.salesmanInfo.ycname
          vm.user.ycempno = vm.form.salesmanInfo.ycempno
          vm.bankcode = vm.form.salesmanInfo.bankcode
          vm.form.salesmanInfo.empname = vm.form.salesmanInfo.empname ? vm.form.salesmanInfo.empname : ''
          vm.form.salesmanInfo.cardnum = vm.form.salesmanInfo.cardnum ? vm.form.salesmanInfo.cardnum : ''
          vm.form.salesmanInfo.phone = vm.form.salesmanInfo.phone ? vm.form.salesmanInfo.phone : ''
          vm.$forceUpdate()
          if (vm.checkRytype == '0' && from.path != '/wtxy') {
            Dialog.alert({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {}
            })
          } else if (vm.checkRytype == '5' && from.path != '/wtxy') {
            if (vm.btntext == '提交申请') {
              vm.btntext = ''
            }
            Dialog.alert({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {}
            })
          } else if (vm.checkRytype === '4' && from.path != '/wtxy') {
            Dialog.alert({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
                vm.checkstatus = 't'
                vm.readonly = false
              }
            })
          } else if (vm.checkRytype === '1' && from.path != '/wtxy') {
            Dialog.failed({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {}
            })
          } else if (vm.checkRytype === '3') {
            vm.checkstatus = 't'
            // eslint-disable-next-line no-unused-expressions
            from.path != '/wtxy' ? Dialog.failed({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
                vm.readonly = false
              }
            }) : ''
          } else if (vm.checkRytype === '6' && from.path != '/wtxy') {
            Dialog.failed({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {}
            })
          }
        }
      })
    })
  },
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [ImageReader.name]: ImageReader,
    [Tag.name]: Tag,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [TabPicker.name]: TabPicker,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Toast.component.name]: Toast.component,
    gua,
    edu,
    family,
    work,
    pdf
  },
  data () {
    return {
      btnloading: false,
      inactive: false,
      dong: {},
      num: 0,
      // 组件控制
      type: 'baseinfo',
      currenttype: ['baseinfo'],
      tjcode: '',
      jginfo: '',
      minDate: new Date('1940/1/1'),
      defaultDate: new Date('1990/1/1'),
      currentDate: new Date(),
      checkRytype: '',
      checkstatus: '',
      // 机构
      showjg: false,
      jglist: [],
      userinfo: {},
      zjlist: [],
      // 推荐人信息
      user: {
        tjname: '',
        tjempno: '',
        upname: '',
        upempno: '',
        ycname: '',
        ycempno: ''
      },
      // 保存按钮
      btntext: '提交申请',
      // 链接数据
      suserid: '',
      tjempno: '',
      tjempname: '',
      userid: '',
      form: {
        salesmanInfo: { tjcode: '', tjlist: [], empno: '', empname: '', cardtype: '', cardnum: '', indate: '', tjempno: '', tjname: '', upempno: '', upname: '', sex: '', nation: '', educational: '', political: '', birth: '', bankcode: '', bankzh: '', bankno: '', zaddr: '', souhttp: '', phone: '', email: '', ycempno: '', ycname: '', degreeno: '', degreelevel: '1', degreename: '' },
        guarantorInfo: [{ guatype: '0', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }, { guatype: '1', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }],
        family: [{ famship: '', name: '', cardtype: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' }],
        edu: [{ school: '', begdate: '', enddate: '', xueli: '', zmr: '' }],
        work: [{ workcompany: '', begdate: '', enddate: '', zhiwu: '', zmren: '', reason: '' }],
        sourceinfo: []
      }, // 申请表单
      // 图片相关
      cardfront: '',
      cardback: '',
      bankcard: '',
      edu: '',
      headpic: '',
      accepic: '',
      // 签名照
      signature: '',
      readonly: false,
      // 下拉框
      cardtype: '',
      sex: '',
      nation: '',
      educational: '',
      political: '',
      politicstatus: '',
      mtcsol: '',
      bankcode: '',
      cardselectdata: [
        {
          value: '1',
          text: '选项一'
        }
      ],
      cardSelectorShow: false,
      sexSelectorShow: false,
      nationSelectorShow: false,
      politicalShow: false,
      eduSelectorShow: false,
      poliSelectorShow: false,
      mtcsolSelectorShow: false,
      bankSelectorShow: false,
      zjSelectorShow: false,
      isDatePickerShow: false,
      isinDateShow: false,
      nationselectdata: [],
      sexselectdata: [],
      eduselectdata: [],
      politicaldata: [],
      familyshipdata: [],
      poliselectdata: [
        {
          value: '0',
          text: '共青团员'
        }
      ],
      mtcsolselectdata: [
        {
          value: '0',
          text: '专职'
        },
        {
          value: '1',
          text: '兼职'
        }
      ],
      bankselectdata: [
        {
          value: '0',
          text: '中国工商银行'
        }
      ],
      datePickerValue: '',
      // 入司开关
      incomset: {},
      canvas: null,
      cxt: null,
      stage_info: null,
      selfcardfront: '',
      selfcardback: '',
      selfbankcard: '',
      selfedu: '',
      selfheadpic: '',
      autograph: '',
      selfautograph: '',
      degreename: '',
      // 子组件翻译字段
      incardtype: '',
      outcardtype: '',
      isnhwx: false,
      getossurl: '',
      isdzrs: true,
      isxz: false,
      showFullScreen: false,
      filepath: '',
      pageCount: 0,
      numPages: 0
    }
  },
  created () {
    this.userinfo = getStorage('u_s', {})
    this.getossurl = getStorage('ossurl', '')
    if (this.userinfo.comid == 'NHWX') {
      this.isnhwx = true
    }
    getMinZJ().then(res => {
      this.zjlist = res.data.data.zjInfo
      this.zjlist.forEach(item => {
        item.value = item.degreeno
        item.text = item.degreename
      })
      this.jginfo = res.data.data.jgInfo.tjname
      this.form.salesmanInfo.tjcode = res.data.data.jgInfo.tjcode
      if (this.isnhwx) {
        this.form.salesmanInfo.degreeno = this.zjlist[0].value
        this.degreename = this.zjlist[0].text
      }
      this.user.tjname = res.data.data.tjempname
      this.user.tjempno = res.data.data.tjempno
      this.form.salesmanInfo.tjname = res.data.data.tjempname
      this.form.salesmanInfo.tjempno = res.data.data.tjempno

      this.$forceUpdate()
    })
    getSelectList({ list: ['XUELI', 'RSCARD', 'MINZHU', 'SEX', 'YWSHIP', 'ZZMM'] }).then(res => {
      res.data.data.forEach(item => {
        switch (item.label) {
          case 'RSCARD':
            this.cardselectdata = item.value
            this.cardselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'YWSHIP':
            this.familyshipdata = item.value
            this.familyshipdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'MINZHU':
            this.nationselectdata = item.value
            this.nationselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'SEX':
            this.sexselectdata = item.value
            this.sexselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'XUELI':
            this.eduselectdata = item.value
            this.eduselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'ZZMM':
            this.politicaldata = item.value
            this.politicaldata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          default:
            break
        }
      })
    })
    selectBaseBankcode().then((res) => {
      this.bankselectdata = res.data.data
      this.bankselectdata.forEach(item => {
        item.value = item.bankid
        item.text = item.bankname
      })
    })
  },
  mounted () {
    var indateMM = new Date().getMonth() + 1
    var indateDD = new Date().getDate()
    if (indateMM < 10) indateMM = '0' + indateMM
    if (indateDD < 10) indateDD = '0' + indateDD
    this.form.salesmanInfo.indate =
      new Date().getFullYear() + '-' + indateMM + '-' + indateDD
  },
  methods: {
    tong (val, str) {
      if (str == 'failed') {
        this.dong = {
          label: val,
          value: 'clear'
        }
      } else if (str == 'info') {
        this.dong = {
          label: val,
          value: ''
        }
      } else if (str == 'succeed') {
        this.dong = {
          label: val,
          value: 'success'
        }
      }
      this.$nextTick(() => {
        this.$refs.toast.show()
        setTimeout(() => {
          this.$refs.toast.hide()
        }, 3000)
      })
    },
    subtouchStart (event) {
      event.preventDefault()
    },
    getRelation () {
      getRelationInfo({ empno: this.userinfo.tjempno, level: '1' }).then((res) => {
        let Data = res.data.data
        this.user.tjname = Data.tjEmpnoInfo.empname
        this.user.tjempno = Data.tjEmpnoInfo.empno
        this.user.upname = Data.upEmpnoInfo.upname
        this.user.upempno = Data.upEmpnoInfo.upempno
        this.user.ycname = Data.ycEmpnoInfo.ycname
        this.user.ycempno = Data.ycEmpnoInfo.ycempno
        this.form.salesmanInfo.tjcode = Data.tjEmpnoInfo.tjcode
        this.form.salesmanInfo.tjname = Data.tjEmpnoInfo.empname
        this.form.salesmanInfo.upname = Data.upEmpnoInfo.upname
        this.form.salesmanInfo.upempno = Data.upEmpnoInfo.upempno
        this.form.salesmanInfo.ycname = Data.ycEmpnoInfo.ycname
        this.form.salesmanInfo.ycempno = Data.ycEmpnoInfo.ycempno
      })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转换成file对象
      return new File([u8arr], filename, { type: mime })
    },
    async saveform () {
      // 上传签名
      // 用file文件来上传oss，获取段路径
      this.currenttype = ['baseinfo']
      if (this.autograph === '' && this.selfautograph === '') {
        const { data } = cloneDeep(this.$refs.signaturePad.saveSignature())
        if (data && data.length > 6) {
          let file = data // 把整个base64给file
          let name = '签名' + '.png' // 定义文件名字（例如：abc.png ， cover.png）
          let conversions = this.dataURLtoFile(file, name) // 调用base64转图片方法
          // conversions就是转化之后的图片文件，
          let formdata = new FormData()
          formdata.append('file', conversions)
          formdata.append('type', 'autograph')
          await uploadfiles(formdata).then(res => {
            this.selfautograph = res.data.data.path
            this.autograph = res.data.data.url
          })
        }
      }
      this.$validator.validateAll().then(valid => {
        if (valid) {
          //  || this.bankcard === ''
          if (this.cardfront === '' || this.cardback === '' || this.headpic === '') {
            this.tong('请上传影像(红星标注)', 'failed')
            return
          }
          if (this.form.salesmanInfo.cardtype === '' ||
            this.form.salesmanInfo.sex === '' || this.form.salesmanInfo.nation === '' ||
            this.form.salesmanInfo.indate === '' || this.form.salesmanInfo.birth === '' ||
            this.form.salesmanInfo.educational === '' || this.form.salesmanInfo.political === '') {
            this.tong('请填写完整必填信息(红星标注)', 'failed')
            return
          }
          if (this.autograph === '') {
            this.tong('请手写签名', 'failed')
            return
          }
          if (!this.isxz) {
            this.tong('请勾选同意相关协议', 'info')
            return
          }
          if (this.form.sourceinfo.length == 0) {
            this.form.sourceinfo.push({ souhttp: this.selfcardfront, soucode: 'idcardq' })
            this.form.sourceinfo.push({ souhttp: this.selfcardback, soucode: 'idcardh' })
            this.form.sourceinfo.push({ souhttp: this.selfbankcard, soucode: 'bank' })
            this.form.sourceinfo.push({ souhttp: this.selfedu, soucode: 'xueli' })
            this.form.sourceinfo.push({ souhttp: this.selfheadpic, soucode: 'headone' })
            this.form.sourceinfo.push({ souhttp: this.selfautograph, soucode: 'autograph' })
          } else {
            this.form.sourceinfo.forEach((item, index) => {
              if (item.soucode == 'idcardq') {
                item.souhttp = this.selfcardfront
              } else if (item.soucode == 'idcardh') {
                item.souhttp = this.selfcardback
              } else if (item.soucode == 'bank') {
                item.souhttp = this.selfbankcard
              } else if (item.soucode == 'xueli') {
                item.souhttp = this.selfedu
              } else if (item.soucode == 'headone') {
                item.souhttp = this.selfheadpic
              } else if (item.soucode == 'autograph') {
                item.souhttp = this.selfautograph
              }
            })
          }

          for (let i = 0; i < this.form.guarantorInfo.length; i++) {
            for (let Guarantor in this.form.guarantorInfo[i]) {
              if (Guarantor !== 'guatype') {
                if (this.form.guarantorInfo[i][Guarantor] !== '') {
                  this.form.guarantorInfo[i].guatype = i
                  break
                } else {
                  this.form.guarantorInfo[i].guatype = ''
                  break
                }
              }
            }
          }
          this.form.salesmanInfo.phone = this.userinfo.phone
          this.btnloading = true
          this.inactive = true
          this.form.salesmanInfo.indateStr = this.form.salesmanInfo.indate // 传参需要indateStr参数，回显需要indate参数
          this.form.salesmanInfo.birthStr = this.form.salesmanInfo.birth // 传参需要birthStr参数，回显需要birth参数
          addmember(this.form).then(res => {
            let applynum = res.data.data
            this.btnloading = false
            this.inactive = false
            if (this.isdzrs) {
              this.tong('资料填写完毕，请继续学习保险基础知识', 'succeed')
              this.$router.push(`/fundamentals?applynum=${applynum}`)
            } else {
              this.tong('入司申请成功', 'succeed')
              this.$router.push('/successpage')
            }
          }).finally(() => {
            this.btnloading = false
            this.inactive = false
          })
        }
      })
      // }
    },
    // 删除图片
    onDeleteImage (tag) {
      switch (tag) {
        case 'bankcard':
          this.bankcard = ''
          this.selfbankcard = ''
          break
        case 'cardfront':
          this.cardfront = ''
          this.selfcardfront = ''
          break
        case 'cardback':
          this.cardback = ''
          this.selfcardback = ''
          break
        case 'edu':
          this.edu = ''
          this.selfedu = ''
          break
        case 'headpic':
          this.headpic = ''
          this.selfheadpic = ''
          break
        default:
          break
      }
    },
    // 图片选择
    oncardfrontSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oncardfrontComplete (name, { dataUrl, file }) {
      Toast.hide()
      let yzformdata = new FormData()
      yzformdata.append('file', file)
      yzformdata.append('type', '0')
      ididentify(yzformdata).then(res => {
        this.form.salesmanInfo.empname = res.data.data.name
        this.form.salesmanInfo.cardnum = res.data.data.idCard
        this.form.salesmanInfo.birth = this.form.salesmanInfo.cardnum.slice(6, 10) + '-' + this.form.salesmanInfo.cardnum.slice(10, 12) + '-' + this.form.salesmanInfo.cardnum.slice(12, 14)
        this.form.salesmanInfo.cardtype = '10'
        this.cardtype = '身份证'
        this.form.salesmanInfo.zaddr = res.data.data.address
        this.form.salesmanInfo.nation = res.data.data.nation
      })
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardfront = res.data.data.path
        this.cardfront = res.data.data.url
      })
    },
    oncardfrontError () { },
    oncardbackSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oncardbackComplete (name, { dataUrl, file }) {
      Toast.hide()
      let yzformdata = new FormData()
      yzformdata.append('file', file)
      yzformdata.append('type', '1')
      ididentify(yzformdata).then(res => {
      })
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardback = res.data.data.path
        this.cardback = res.data.data.url
      })
    },
    oncardbackError () { },
    onbankcardSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    onbankcardComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      bankidentify(formdata).then(res => {
        // eslint-disable-next-line no-unused-expressions
        res.data.data ? '' : Toast.loading('图片未识别成功')
        this.form.salesmanInfo.bankcode = ''
        this.bankcode = ''
        this.form.salesmanInfo.bankno = ''
        this.form.salesmanInfo.bankno = res.data.data && res.data.data.bank_card_number ? res.data.data.bank_card_number.replace(/\s/g, '') : ''
        let bankcode = res.data.data ? res.data.data.bank_name : ''
        this.bankselectdata.forEach(item => {
          if (item.text == bankcode) {
            this.form.salesmanInfo.bankcode = item.value
            this.bankcode = item.text
          }
        })
      })
      formdata.delete('type')
      formdata.append('type', 'bank')
      uploadfiles(formdata).then(res => {
        this.selfbankcard = res.data.data.path
        this.bankcard = res.data.data.url
      })
    },
    onbankcardError () { },
    oneduSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oneduComplete (name, { dataUrl, file }) {
      Toast.hide()

      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'xueli')
      uploadfiles(formdata).then(res => {
        this.selfedu = res.data.data.path
        this.edu = res.data.data.url
      })
    },
    oneduError (name, { msg }) { Toast.failed(msg) },
    onheadpicSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    onheadpicComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'headone')
      uploadfiles(formdata).then(res => {
        this.selfheadpic = res.data.data.path
        this.headpic = res.data.data.url
      })
    },
    onheadpicError () { },
    onacceSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    onacceComplete (name, { dataUrl, file }) {
      Toast.hide()
      this.accepic = dataUrl
    },
    onacceError () { },
    // 下拉框
    showcardSelector (selectag) {
      this.cardSelectorShow = true
    },
    oncardtypeChoose ({ value, text }) {
      this.form.salesmanInfo.cardtype = value
      this.cardtype = text
      let cardnum = this.form.salesmanInfo.cardnum
      if (cardnum.length === 18) {
        this.form.salesmanInfo.birth = cardnum.slice(6, 10) + '-' + cardnum.slice(10, 12) + '-' + cardnum.slice(12, 14)
        this.form.salesmanInfo.sex = parseInt(cardnum.slice(-2, -1)) % 2 == 1 ? '2' : '1'
        this.sex = parseInt(cardnum.slice(-2, -1)) % 2 == 1 ? '男' : '女'
      }
    },
    onsexChoose ({ value, text }) {
      this.form.salesmanInfo.sex = value
      this.sex = text
      /* this.selectdata.sex = text */
    },
    showsexSelector () {
      this.sexSelectorShow = true
    },
    shownationSelector () {
      this.nationSelectorShow = true
    },
    onnationChoose ({ value, text }) {
      this.form.salesmanInfo.nation = value
      this.nation = text
      /* this.selectdata.nation = text */
    },
    showepolitical () {
      this.politicalShow = true
    },
    showeduSelector () {
      this.eduSelectorShow = true
    },
    oneduChoose ({ value, text }) {
      this.form.salesmanInfo.educational = value
      this.educational = text
      /* this.selectdata.educational = text */
    },
    politicalChoose ({ value, text }) {
      this.form.salesmanInfo.political = value
      this.political = text
      /* this.selectdata.educational = text */
    },
    showpoliSelector () { this.poliSelectorShow = true },
    onpoliChoose ({ value, text }) {
      this.form.politicstatus = value
      this.politicstatus = text
    },
    showmtcsolSelector () { this.mtcsolSelectorShow = true },
    onmtcsolChoose ({ value, text }) {
      this.form.mtcsol = value
      this.mtcsol = text
    },
    showbankSelector () { this.bankSelectorShow = true },
    onbankChoose ({ value, text }) {
      this.form.salesmanInfo.bankcode = value
      this.bankcode = text
    },
    showzjSelector () { this.zjSelectorShow = true },
    onzjChoose ({ value, text }) {
      this.form.salesmanInfo.degreeno = value
      this.degreename = text
    },
    clearcanvas (type) {
      /*
      * 清空签名
      * */
      if (type == 'qm') {
        this.$refs.signaturePad.undoSignature()
      } else {
        this.autograph = ''
        this.selfautograph = ''
      }
    },
    // 出生日期
    onDatePickerChange () { },
    onDatePickerConfirm (columnsValue) {
      this.form.salesmanInfo.birth = this.$refs.datePicker2.getFormatDate('yyyy-MM-dd')
    },
    oninDateChange () { },
    oninDateConfirm () {
      this.form.salesmanInfo.indate = this.$refs.datePicker1.getFormatDate('yyyy-MM-dd')
    },
    inputcardnum () {
      let cardnum = this.form.salesmanInfo.cardnum
      if (cardnum.length === 18) {
        if (this.cardtype === '居民身份证') {
          this.form.salesmanInfo.sex = parseInt(cardnum.slice(-2, -1)) % 2 == 1 ? '2' : '1'
          this.sex = parseInt(cardnum.slice(-2, -1)) % 2 == 1 ? '男' : '女'
        }
      }
    },
    initpic () {
      if (this.form.sourceinfo.length !== 0) {
        this.form.sourceinfo.forEach(item => {
          switch (item.soucode) {
            case 'idcardq':
              this.cardfront = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfcardfront = item.souhttp
              break
            case 'idcardh':
              this.cardback = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              //   this.getossurl + item.souhttp
              this.selfcardback = item.souhttp
              break
            case 'bank':
              this.bankcard = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfbankcard = item.souhttp
              break
            case 'xueli':
              this.edu = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfedu = item.souhttp
              break
            case 'headone':
              // this.headpic = this.getossurl + item.souhttp
              this.headpic = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfheadpic = item.souhttp
              break
            case 'autograph':
              this.autograph = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfautograph = item.souhttp
              break
            default:
              break
          }
        })
        this.$forceUpdate()
      }
    },
    toshow () {
      this.$router.push('/wtxy')
    }
  }
}
</script>
<style lang="stylus" scoped>
.topbox {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 2rem;
}

.baseinfo {
  background-color: #F6F6F6;
}

.submit {
  height: 1.2rem;
  line-height: 1.2rem;
  // padding: 40px 40px;
  margin: 80px auto;
  width: 90%;
  background-color: color-primary;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  border-radius: 2rem;
}

/deep/ .md-field {
  padding: 0 0.4rem;
  border: none;
  background-color: #fff;
  margin-bottom: 0.3rem;
}

/deep/ .md-field-item-content {
  min-height: 1.3rem;
}

/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
    font-size: 0.35rem;
  }
}

/deep/ .md-field-item-placeholder {
  color: #c5cad5;
  font-weight: 400;
  font-size: 0.32rem;
  position: relative;
  bottom: 0.01rem;
}

.handsign {
  position: relative;
}

.image-reader-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image-reader-item {
  position: relative;
  float: left;
  width: 48%;
  margin-bottom: 2%;
  background: #FFF;
  // box-shadow: 0 5px 20px rgba(197, 202, 213, 0.25);
  box-sizing: border-box;
  list-style: none;
  border-radius: 4px;
  background-size: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  div {
    height: 2.5rem;
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  p {
    width: 100%;
    margin-top: 15px;
    font-size: 32px;
    color: #CBC;
    text-align: center;
  }

  &.add {
    .md-icon {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
    }

    p {
      width: 100%;
      margin-top: 15px;
      font-size: 32px;
      color: #CBC;
      text-align: center;
    }
  }

  .image-reader-item-del {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    opacity: 0.8;

    .md-icon-close {
      font-size: 24px;
    }
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

.store {
  border: none;
  box-shadow: none;
}

.marginb {
  margin-bottom: 0.3rem;
}

/* 协议 */
.xyword {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 5%;

  p {
    font-size: 34px;
    font-family: PingFang SC;
    color: #383838;
  }

  .frontpic {
    img {
      width: 34px;
      height: 34px;
    }

    span {
      font-size: 34px;
      color: #108EE9;
      font-family: PingFang SC;
      margin: 0 2px;
    }
  }
}

.closepdf {
  position: fixed !important;
  /* right: 0.25rem; */
  /* top: 0.25rem; */
  bottom: 0px !important;
  margin: 0 auto !important;
  width: 100vw !important;
  margin: auto !important;
  /* margin-left: 10vw; */
  height: 110px !important;
  background-color: #EC2D38 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white;
  z-index: 1601;
}

// /deep/ .md-icon-clear:before, .md-icon-fail:before {
//   content: '';
// }
</style>
