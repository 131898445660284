<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-07 16:11:09
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-08 11:35:54
 -->
<template>
  <div>
    <md-field>
      <div class="edu"
           v-for="(item,index) in form.edu"
           :key="index">
        <md-input-item :title="'教育经历'+(index+1)"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="就读学校："
                       placeholder="就读学校"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.school"
                       align="right"></md-input-item>
        <md-field-item title="起始年限："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       :content="item.begdate"
                       @click.native="startDateShow(index)">
        </md-field-item>
        <md-date-picker ref="start"
                        v-model="istartDateShow[index]"
                        v-if="checkstatus === 't'"
                        type="custom"
                        title="选择起始年限"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onstartDateChange(index)"
                        @confirm="onstartConfirm(index)"></md-date-picker>
        <md-field-item title="终止年限："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       :content="item.enddate"
                       @click.native="endDateShow(index)">
        </md-field-item>
        <md-date-picker ref="end"
                        v-model="isendDateShow[index]"
                        type="custom"
                        v-if="checkstatus === 't'"
                        title="选择终止年限"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onendDateChange(index)"
                        @confirm="onendConfirm(index)"></md-date-picker>
        <md-field-item title="学历："
                       :content="item.xueliname"
                       @click="showeduSelector(index)"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false">
        </md-field-item>

        <md-selector v-model="eduSelectorShow[index]"
                     :data="eduselectdata"
                     title="学历"
                     large-radius
                     @show="showwhich(index)"
                     @choose="oneduChoose"></md-selector>
        <md-input-item title="证明人："
                       placeholder="证明人"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="item.zmr"
                       align="right"></md-input-item>
        <md-button :type="form.edu.length == 1?'disabled':'warning'"
                   v-if="checkstatus !== 't'?false:true"
                   plain
                   round
                   @click="del(index)">删除</md-button>
      </div>
      <md-button class="addbtn"
                 :type="checkstatus !== 't' ?'disabled':'primary'"
                 plain
                 round
                 @click="add">添加教育经历</md-button>
      <div class="btn">
        <md-button type="primary"
                   @click="back"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{checkstatus == 't'?'上一步':'上一页'}}</md-button>
        <md-button type="primary"
                   @click="saveform"
                   v-if="checkstatus === 't' || (checkstatus != 't' && btntext == '下一页')"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{btntext}}</md-button>
      </div>
    </md-field>
  </div>
</template>
<script>
import { addmember } from '@/api/agent/agent'
import { Button, DatePicker, InputItem, Field, Toast, Selector, FieldItem } from 'mand-mobile'
export default {
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button
  },
  data () {
    return {
      btntext: '下一步', // 按钮内容
      // 下拉
      item: '',
      xueli: [],
      begdate: [],
      enddate: [],
      eduselectdata: [],
      istartDateShow: [false],
      isendDateShow: [false],
      eduSelectorShow: [false],
      form: {},
      incomset: {},
      checkstatus: '',
      isdzrs: false
    }
  },
  created () {
    this.eduselectdata = this.$parent.eduselectdata
    this.checkstatus = this.$parent.checkstatus
    this.form = this.$parent.form
    this.incomset = this.$parent.incomset
    this.isdzrs = this.$parent.isdzrs
    if (this.incomset.works) {
      this.btntext = this.checkstatus == 't' ? '下一步' : '下一页'
    } else { this.btntext = '提交申请' }
  },
  updated () {
    this.checkstatus = this.$parent.checkstatus
  },
  methods: {
    saveform () {
      if (this.incomset.works) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'work'
      } else {
        addmember(this.form).then(res => {
          if (this.isdzrs) {
            // location.reload()
            Toast.succeed('资料填写完毕，请继续学习保险基础知识')
            this.$router.push('/fundamentals')
          } else {
            Toast.succeed('入司申请成功')
            this.$router.push('/successpage')
          }
        })
      }
    },
    showeduSelector (index) {
      this.eduSelectorShow[index] = true
      this.$forceUpdate()
    },
    showwhich (index) { this.item = index },
    oneduChoose ({ value, text }) {
      // this.xueli[this.item] = text
      this.form.edu[this.item].xueliname = text
      this.form.edu[this.item].xueli = value
    },
    startDateShow (index) {
      this.istartDateShow[index] = true
      this.$forceUpdate()
    },
    onstartDateChange (index) {

    },
    onstartConfirm (index) {
      this.form.edu[index].begdate = this.$refs['start'][index].getFormatDate('yyyy-MM-dd')
      this.begdate[index] = this.form.edu[index].begdate
      this.$forceUpdate()
    },
    endDateShow (index) {
      this.isendDateShow[index] = true
      this.$forceUpdate()
    },
    onendDateChange (index) { },
    onendConfirm (index) {
      this.form.edu[index].enddate = this.$refs['end'][index].getFormatDate('yyyy-MM-dd')
      this.enddate[index] = this.form.edu[index].enddate
      this.$forceUpdate()
    },
    // 添加删除
    del (index) {
      this.form.edu.splice(index, 1)
    },
    add () {
      this.form.edu.push({ famship: '', name: '', cardtype: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' })
    },
    back () {
      this.$parent.type = this.$parent.currenttype[this.$parent.currenttype.length - 1]
      this.$parent.currenttype.pop()
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

.addbtn {
  margin: 20px 0;
}

.btn {
  display: flex;

  .md-button {
    flex: 1;
    margin: 5px;
  }
}
</style>
