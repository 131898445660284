<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-07 10:58:09
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-08 11:35:43
 -->
<template>
  <div>
    <md-field>
      <div class="ingua"
           v-if="incomset.internalGuarantor">
        <md-input-item title="内担保人信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="工号："
                       placeholder="工号"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guaempno"
                       align="right"></md-input-item>
        <md-input-item title="姓名："
                       placeholder="姓名"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guaname"
                       align="right"></md-input-item>
        <md-field-item title="证件类型："
                       :content="incardtype"
                       @click="showincardSelector"
                       :disabled="checkstatus === 't'?false:true"
                       arrow
                       :solid="false">
        </md-field-item>

        <md-selector v-model="incardSelectorShow"
                     :data="incardselectdata"
                     title="证件类型"
                     default-value="1"
                     large-radius
                     @choose="incardtypeChoose"></md-selector>
        <md-input-item title="证件号码："
                       placeholder="证件号码"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guacardnum"
                       align="right"
                       :name="incardtype != '' ? 'nguacardnum':''"
                       :class="incardtype != '' ? 'require':''"
                       v-validate="incardtype != '' ? 'required':''"
                       :error="incardtype != ''?errors.first('nguacardnum'):''"></md-input-item>
        <md-input-item title="职务："
                       placeholder="职务"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guazhiwu"
                       align="right"></md-input-item>
        <md-input-item title="电话："
                       placeholder="电话"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guatel"
                       align="right"></md-input-item>
        <md-input-item title="地址："
                       placeholder="地址"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[0].guaaddr"
                       align="right"></md-input-item>
      </div>
      <div class="outgua"
           v-if="incomset.outsideGuarantor">
        <md-input-item title="外担保人信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="姓名："
                       placeholder="姓名"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[1].guaname"
                       align="right"></md-input-item>
        <md-field-item title="证件类型："
                       :content="outcardtype"
                       @click="showoutcardSelector"
                       :disabled="checkstatus === 't'?false:true"
                       arrow
                       :solid="false">
        </md-field-item>
        <md-selector v-model="outcardSelectorShow"
                     :data="outcardselectdata"
                     title="证件类型"
                     default-value="1"
                     large-radius
                     @choose="outcardtypeChoose"></md-selector>
        <md-input-item title="证件号码："
                       placeholder="证件号码"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.guarantorInfo[1].guacardnum"
                       align="right"
                       :name="outcardtype != '' ? 'wguacardnum':''"
                       :class="outcardtype != '' ? 'require':''"
                       v-validate="outcardtype != '' ? 'required':''"
                       :error="outcardtype != ''?errors.first('wguacardnum'):''"></md-input-item>
        <md-input-item title="职务："
                       placeholder="职务"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[1].guazhiwu"
                       align="right"></md-input-item>
        <md-input-item title="电话："
                       placeholder="电话"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.guarantorInfo[1].guatel"
                       align="right"></md-input-item>
        <md-input-item title="地址："
                       :readonly="checkstatus === 't'?false:true"
                       placeholder="地址"
                       :solid="false"
                       v-model="form.guarantorInfo[1].guaaddr"
                       align="right"></md-input-item>
      </div>
      <div class="btn">
        <md-button type="primary"
                   @click="back"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{checkstatus == 't'?'上一步':'上一页'}}</md-button>
        <md-button type="primary"
                   @click="saveform"
                   v-if="checkstatus === 't' || (checkstatus != 't' && btntext == '下一页')"
                   :style=" `backgroundColor:${$parent.colorprimary};`">{{btntext}}</md-button>
      </div>
    </md-field>
  </div>
</template>
<script>
import { addmember } from '@/api/agent/agent'
import { Button, DatePicker, InputItem, Field, Toast, Selector, FieldItem } from 'mand-mobile'
export default {
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button
  },
  props: {
    incardtype: String,
    outcardtype: String
  },
  data () {
    return {
      // 按钮内容
      btntext: '下一步',
      // 证件类型选择框
      incardSelectorShow: false,
      outcardSelectorShow: false,
      outcardselectdata: [],
      incardselectdata: [],
      // 表单
      form: {},
      // 配置开关
      incomset: {},
      checkstatus: '',
      isdzrs: false
    }
  },
  created () {
    this.incomset = this.$parent.incomset
    this.form = this.$parent.form
    this.isdzrs = this.$parent.isdzrs
    if (this.incomset.internalGuarantor && this.$parent.user.tjempno && this.$parent.user.tjname) {
      this.form.guarantorInfo[0].guaempno = this.$parent.user.tjempno
      this.form.guarantorInfo[0].guaname = this.$parent.user.tjname
    }
    this.checkstatus = this.$parent.checkstatus
    this.incardselectdata = this.$parent.cardselectdata
    this.outcardselectdata = this.$parent.cardselectdata
    if (this.incomset.familys || this.incomset.educations || this.incomset.works) {
      this.btntext = this.checkstatus == 't' ? '下一步' : '下一页'
    } else { this.btntext = '提交申请' }
  },
  updated () {
    this.checkstatus = this.$parent.checkstatus
  },
  methods: {
    saveform () {
      // this.$validator.validateAll().then(valid => {
      //   if (valid) {
      this.form.guarantorInfo.forEach((item, index) => {
        if (item.guatype == '') {
          item.guatype = index
        }
      })
      if (this.incomset.familys) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'family'
      } else if (this.incomset.educations) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'edu'
      } else if (this.incomset.works) {
        this.$parent.currenttype.push(this.$parent.type)
        this.$parent.type = 'work'
      } else {
        addmember(this.form).then(res => {
          if (this.isdzrs) {
            // location.reload()
            Toast.succeed('资料填写完毕，请继续学习保险基础知识')
            this.$router.push('/fundamentals')
          } else {
            Toast.succeed('入司申请成功')
            this.$router.push('/successpage')
          }
        })
      }
      //   }
      // })
    },
    // 选择框
    showincardSelector () { this.incardSelectorShow = true },
    showoutcardSelector () { this.outcardSelectorShow = true },
    incardtypeChoose ({ text, value }) {
      this.$emit('update:incardtype', text)
      this.form.guarantorInfo[0].guacardtype = value
    },
    outcardtypeChoose ({ text, value }) {
      this.$emit('update:outcardtype', text)
      this.form.guarantorInfo[1].guacardtype = value
    },
    back () {
      this.$parent.type = this.$parent.currenttype[this.$parent.currenttype.length - 1]
      this.$parent.currenttype.pop()
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

.btn {
  display: flex;

  .md-button {
    flex: 1;
    margin: 5px;
  }
}

/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}
</style>
